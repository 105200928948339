import React from "react"
import {Fragment} from "react";
import Layout from "../components/Layout";
import {registerUser} from "../utils/api";
import {NotificationManager} from "../components/Notification";
import './index.scss'
import {Link} from "gatsby";
import {
    ArrowRightIcon,
    CheckIcon,
    ExternalLinkIcon,
    FlagIcon,
    MailIcon,
    SelectorIcon,
    UserCircleIcon
} from "@heroicons/react/outline";
import {Listbox, Transition} from "@headlessui/react";
import localization from "../lang"
import Alert from "../components/Alert";
import Loader from "../components/Loader";

class Index extends React.Component {
    state = {
        promoCode: process.env.GATSBY_PROMO_CODE,
        tosAgreed: false,
        gdprAgreed: false,
        email: undefined,
        name: undefined,
        isButtonEnabled: true,
        registrationDone: false,
        questionnaireHash: undefined,
        preferredLanguage: 'en_US',
        enumLanguage: {
            options: [
                {code: 'en_US', label: 'English'},
                {code: 'cs_CZ', label: 'Čeština'}
            ]
        },
        isSubmitted: false,
    }

    componentDidMount = () => {

    }

    toggleGDPR = () => {
        this.setState({
            gdprAgreed: this.state.gdprAgreed === false
        })
    }

    toggleToS = () => {
        this.setState({
            tosAgreed: this.state.tosAgreed === false
        })
    }

    updateName = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    updateEmail = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    updatePreferredLanguage = (lang) => {
        this.setState({
            preferredLanguage: lang.id
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        const request = {
            name: this.state.name,
            email: this.state.email,
            preferred_language: this.state.preferredLanguage,
            promo_code: this.state.promoCode,
            data_consent: this.state.gdprAgreed,
            tos_agreed: this.state.tosAgreed,
        }

        registerUser(request, () => {
            this.setState({
                isButtonEnabled: false,
                isSubmitted: true,
            })
        }).then(response => {
            this.setState({
                registrationDone: true,
                questionnaireHash: response.data.data.report
            })
        }).catch(e => {
            const {response} = e
            const {errors} = response.data

            for (const err of errors) {
                NotificationManager.error(err.title, 3000)
            }
        }).finally(() => {
            this.setState({
                isButtonEnabled: true,
                isSubmitted: false,
            })
        })
    }

    render() {
        const {
            tosAgreed,
            gdprAgreed,
            registrationDone,
            questionnaireHash,
            enumLanguage,
            preferredLanguage,
            isSubmitted,
        } = this.state
        const languages = [];
        let selectedLanguage = {};
        enumLanguage.options.forEach((o) => {
            languages.push({
                id: o.code,
                name: o.label,
                unavailable: false,
            })

            if (o.code === preferredLanguage) {
                selectedLanguage = {
                    id: o.code,
                    name: o.label,
                    unavailable: false,
                }
            }
        })

        let locale;
        if (localization.hasOwnProperty(preferredLanguage)) {
            locale = localization[preferredLanguage]
        } else {
            locale = localization['en_US']
        }

        const formSubmittable = (tosAgreed === true && gdprAgreed === true)
        let formSubmitClasses = ''

        if (formSubmittable === true) {
            formSubmitClasses = 'block w-full max-w-xs mx-auto rounded-lg px-3 py-3 font-semibold bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white'
        } else {
            formSubmitClasses = 'block w-full max-w-xs mx-auto rounded-lg px-3 py-3 font-semibold bg-gray-400 text-gray-500'
        }

        const registrationMessage = (
            <div>
                <p className={'mb-2'}>
                    Your registration was successful. We've sent you an email with URL to verify
                    your e-mail.
                </p>
                <p>
                    You can also <Link
                    className={'underline font-semibold'}
                    to={'/r/' + questionnaireHash}>
                    start filling the questionnaire <ArrowRightIcon className={'h-3 w-3 text-white inline-block'}/></Link>
                </p>
            </div>
        )

        return (
            <Layout>
                <div className={'min-w-screen min-h-screen bg-gray-900 flex items-center justify-center'}>
                    <main
                        className={'container mx-auto my-5 bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden'}
                        style={{maxWidth: '1000px'}}>
                        <div className={'md:flex w-full'}>
                            <div className={'w-full md:w-7/12 py-10 px-5 md:px-10'}>
                                {registrationDone !== true &&
                                <div>
                                    <div className={'text-center mb-10'}>
                                        <h1 className={'font-bold text-3xl text-gray-900'}>4Elements Inventory</h1>
                                    </div>
                                    <div>
                                        <Loader active={isSubmitted} title={locale.app.loading.title} message={locale.app.loading.message}/>
                                        <form className={'-mx-3'} onSubmit={event => {
                                            this.handleSubmit(event)
                                        }}>
                                            <div className={'w-full px-3 mb-5'}>
                                                <label htmlFor="name"
                                                       className={'text-sm font-semibold px-1'}>{locale.registrationForm.name.label}</label>
                                                <div className={'flex'}>
                                                    <div
                                                        className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                        <UserCircleIcon className={'h-5 w-5 text-gray-400'}/>
                                                    </div>
                                                    <input
                                                        onChange={this.updateName}
                                                        id="name"
                                                        type="text"
                                                        className={'w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500'}
                                                        name={'name'}
                                                        placeholder={locale.registrationForm.name.placeholder}
                                                        required/>
                                                </div>
                                            </div>
                                            <div className={'w-full px-3 mb-5'}>
                                                <label htmlFor="email"
                                                       className={'text-sm font-semibold px-1'}>{locale.registrationForm.email.label}</label>
                                                <div className={'flex'}>
                                                    <div
                                                        className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                                        <MailIcon className={'h-5 w-5 text-gray-400'}/>
                                                    </div>
                                                    <input
                                                        onChange={this.updateEmail}
                                                        id="email"
                                                        type="email"
                                                        className={'w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500'}
                                                        name={'email'}
                                                        placeholder={locale.registrationForm.email.placeholder}
                                                        required/>
                                                </div>
                                            </div>
                                            <div className={'w-full px-3 mb-5'}>
                                                <label htmlFor={'preferredLanguage'}
                                                       className={'text-sm font-semibold px-1'}>{locale.registrationForm.preferredLanguage.label}</label>
                                                <div>
                                                    <Listbox value={selectedLanguage}
                                                             onChange={this.updatePreferredLanguage}>
                                                        <div className="relative mt-1">
                                                            <Listbox.Button
                                                                className={'relative w-full py-2 pl-3 pr-10 text-left rounded-lg border-2 border-gray-200 outline-none bg-white'}>
                                                                <span
                                                                    className={'absolute inset-y-0 left-2 flex items-center pr-2 pointer-events-none'}>
                                                                    <FlagIcon className={'h-5 w-5 text-gray-400'}/>
                                                                </span>
                                                                <span
                                                                    className={'block truncate pl-7'}>{selectedLanguage.name}</span>
                                                                <span
                                                                    className={'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'}>
                                                                    <SelectorIcon className={'w-5 h-5 text-gray-400'}
                                                                                  aria-hidden={'true'}/>
                                                                </span>
                                                            </Listbox.Button>
                                                            <Transition
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options
                                                                    className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {languages.map((lang, idx) => (
                                                                        <Listbox.Option
                                                                            key={idx}
                                                                            className={({active}) => `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'}
                                                                                cursor-default select-none relative py-2 pl-10 pr-4`
                                                                            }
                                                                            value={lang}
                                                                        >
                                                                            {({selected, active}) => (
                                                                                <>
                                                                                  <span
                                                                                      className={`${
                                                                                          selected ? 'font-medium' : 'font-normal'
                                                                                      } block truncate`}
                                                                                  >
                                                                                    {lang.name}
                                                                                  </span>
                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={`${
                                                                                                active ? 'text-amber-600' : 'text-amber-600'
                                                                                            }
                                                                                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                                                                        >
                                                                                            <CheckIcon
                                                                                                className="w-5 h-5"
                                                                                                aria-hidden="true"/>
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                            </div>
                                            <div className={'w-full px-3 mb-5'}>
                                                <div>
                                                    <label htmlFor={'tos'} className={'inline-flex items-center'}>
                                                        <input type="checkbox" id="tos"
                                                               onChange={this.toggleToS}
                                                               className={'rounded'}
                                                        />
                                                        <span className={'ml-2'}>
                                                                {locale.registrationForm.tos.label}
                                                        </span>
                                                        <a
                                                            className={'ml-2'}
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={locale.registrationForm.tos.url}>
                                                            <ExternalLinkIcon className={'h-5 w-5 text-indigo-500'}/>
                                                        </a>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label htmlFor={'gdpr'} className={'inline-flex items-center'}>
                                                        <input type="checkbox" id="gdpr"
                                                               onChange={this.toggleGDPR}
                                                               className={'rounded'}/>
                                                        <span className={'ml-2'}>
                                                            {locale.registrationForm.gdpr.label}
                                                        </span>
                                                        <a
                                                            className={'ml-2'}
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={locale.registrationForm.gdpr.url}>
                                                            <ExternalLinkIcon className={'h-5 w-5 text-indigo-500'}/>
                                                        </a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={'w-full px-3 mt-8'}>
                                                <button
                                                    className={formSubmitClasses}
                                                    type="submit"
                                                    name="action"
                                                    disabled={formSubmittable !== true}
                                                >
                                                    {locale.registrationForm.submit}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                }
                                {registrationDone === true &&
                                <Alert message={registrationMessage}/>
                                }
                            </div>
                            <div className={'hidden md:flex flex-wrap content-center w-5/12 bg-white py-10 px-10'}>
                                <img src={'/elements.png'} alt={'4Elements Inventory'}/>
                            </div>
                        </div>
                    </main>
                </div>
            </Layout>
        )
    }
}

export default Index
